import { AxiosRequestConfig } from 'axios';
import { createContext, useContext } from 'react';

export const AxiosConfigContext = createContext<AxiosRequestConfig>(
  {} as AxiosRequestConfig
);

interface AxiosConfigProviderProps {
  axiosConfig: AxiosRequestConfig;
  children: React.ReactNode;
}

export const AxiosConfigProvider = ({
  children,
  axiosConfig,
}: AxiosConfigProviderProps) => {
  return (
    <AxiosConfigContext.Provider value={axiosConfig}>
      {children}
    </AxiosConfigContext.Provider>
  );
};

export const useAxiosConfig = () => {
  return useContext(AxiosConfigContext);
};
