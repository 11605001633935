import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface GenerateRecipeCardProps {
  className?: ClassValue;
}

export default function GenerateRecipeCard({
  className,
}: GenerateRecipeCardProps) {
  return (
    <div className={cn('bg-primary-50 px-4 py-3 rounded-sm', className)}>
      <p className="text-xs text-primary">
        <span className="font-semibold">Don’t know your recipe?</span> Reggie,
        our AI recipe generator, can create a recipe for you!
        <br className="hidden sm:block" /> Simply tell us your recipe name and
        we&apos;ll do the rest.
      </p>
    </div>
  );
}
