import { cn } from '@shared/lib/utils';
import { FC } from 'react';

export interface LogoProps {
  variant?: 'icon' | 'name' | 'stacked';
  color?: 'light' | 'dark';
  className?: string;
}

import iconLogoWhite from './assets/logo-icon-white.svg';
import iconLogo from './assets/logo-icon.svg';
import nameLogoWhite from './assets/logo-name-white.svg';
import nameLogo from './assets/logo-name.svg';
import stackedLogoWhite from './assets/logo-stacked-white.svg';
import stackedLogo from './assets/logo-stacked.svg';

const Logo: FC<LogoProps> = ({
  variant = 'icon',
  className,
  color = 'dark',
}) => {
  let img = '';

  switch (variant) {
    case 'icon':
      color === 'dark' ? (img = iconLogo) : (img = iconLogoWhite);
      break;
    case 'name':
      color === 'dark' ? (img = nameLogo) : (img = nameLogoWhite);
      break;
    case 'stacked':
      color === 'dark' ? (img = stackedLogo) : (img = stackedLogoWhite);
      break;
    default:
      img = nameLogo;
      break;
  }

  return <img className={cn(className)} src={img} alt="My Emissions logo" />;
};

export default Logo;
