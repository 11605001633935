import { Badge } from '@shared/components/ui/badge';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface BadgeCellProps {
  className?: ClassValue;
  text?: string;
}

export function BadgeCell({ className, text }: BadgeCellProps) {
  return (
    <Badge
      variant="outline"
      className={cn(
        'whitespace-nowrap font-normal text-muted-foreground',
        className
      )}
    >
      {text}
    </Badge>
  );
}
