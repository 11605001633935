import { Icons } from '@shared/components/content/icons';
import ProgressBarWithDuration from '@shared/components/progress-bar-with-duration';

interface ReggieLoaderProps {
  forceComplete?: boolean;
}

export default function ReggieLoader({
  forceComplete = false,
}: ReggieLoaderProps) {
  return (
    <div className="flex w-[320px] items-center justify-center">
      <ProgressBarWithDuration
        duration={4000}
        statusMessages={[
          {
            message: 'Analysing recipe name...',
            icon: <Icons.scanSearch className="size-5" />,
          },
          {
            message: 'Calculating ingredients...',
            icon: <Icons.carrot className="size-5" />,
          },
          {
            message: 'Estimating servings...',
            icon: <Icons.scale className="size-5" />,
          },
          {
            message: 'Finalising the recipe...',
            icon: <Icons.loader2 className="size-5 animate-spin" />,
          },
        ]}
        forceComplete={forceComplete}
      />
    </div>
  );
}
