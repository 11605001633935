// consider moving this to InfoPopOver.tsx especially if instead of rendering plain text

// we want to render custom jsx
export const INFO_SUBJECT_MATTERS_MESSAGES: { [key: string]: string } = {
  'No Date': 'This will be set when the product assessment is complete.',
  'Carbon Rating': 'This product contains a drink and will not be labelled.',
  'System Boundary':
    ' The system boundary defines what stages of your product’s life cycle we have assessed. My Emissions offers two system boundaries: cradle-to-store, which covers emissions from farming up to when the food reaches a store, restaurant, or home, and cradle-to-grave which extends across your product’s entire life-cycle and includes any later activities like cooking. Cradle-to-store is our standard system boundary and the one used for our carbon labels.',
  'Emissions Per Serving': 'Emissions per Serving Info',
  'Total Emissions': 'Emissions Info',
  'Carbon Intensity':
    'The carbon footprint of 1kg of your product. This value corresponds to My Emissions’ carbon ratings which can be used to compare the impact of products where the total or serving weight differs',
  'Emissions Breakdown': 'Emissions Breakdown Info',
  'Lowest Emissions Product':
    'Your three products with the lowest carbon intensity.',
  'Highest Emissions Product':
    'Your three products with the highest carbon intensity.',
  'Lowest Emissions Ingredients':
    'Your three ingredients with the lowest carbon intensity.',
  'Highest Emissions Ingredients':
    'Your three ingredients with the highest carbon intensity.',
  'Breakdown of Product Ratings':
    'Your average carbon intensity is based on the average intensity of all active products in your account.',
  'Average Life Cycle Emissions':
    'See which life-cycle stage contributes the most to your emissions.',
  'Average Product Carbon Intensity':
    'Calculated based upon the active products in your account in the labelled month.',
  'Average Emissions From Your Supply Chain':
    "This section covers all the transport to get your products from your manufacturer to a retail store, resturant, or end customer. It does not include the transport of your individual ingredients from farm to manufacturer, which is reported in the 'Ingredients' tab.",
  "Percentage of Product's Total Emissions - Ingredients":
    "This is the percentage of your product’s carbon footprint coming from the product's ingredients",
  "Percentage of Product's Total Transport Emissions":
    "This is the percentage of your product’s carbon footprint coming from the product's transport",
  "Percentage of Product's Total Packaging Emissions":
    "This is the percentage of your product’s carbon footprint coming from the product's packaging",
  "Percentage of Product's Total Processing Emissions":
    "This is the percentage of your product’s carbon footprint coming from the product's processing",
  "Percentage of Product's Packaged":
    'Refers to cases where you sell the same product packaged and not-packaged (eg a restaurant that serves meals in-store and via delivery).',
  "Percentage of Product's Transported":
    'Refers to cases where the same product is sometimes transported and sometimes not (eg a restaurant that serves meals in-store and via delivery).',
  'Most Used Ingredients':
    'See whether the ingredients you use the most have a low or high carbon footprint. Your most used ingredients are calculated by summing the quantities that a food appears per serving, across all products it appears in',
  NoSuitableFoodMatch:
    'Tick this checkbox if you cannot find a suitable food for this ingredient from the list',
  'Product Processing':
    'Use the percentage field if only a proportion of products undergo a certain processing step.',
  'Product Packaging':
    'If this product is packaged in 2 or more ways, add each option separately and use the percentage column to weight the different packaging options.',
  'Product Transport':
    'Sometimes products are transported in different ways, or not always transported. Add each journey individually, and then use the % field to mark what proportion the product uses each journey.',
  'Processing Description':
    'Please summarise how your product is manufactured, to help us best construct your processing.',
  'Packaging Description':
    'Please describe the packaging used for your product.',
  'Transport Description':
    'Please provide a description of the route your products take from their manufacturer to their final destination',
  'No Suitable Match - negligible emissions':
    'This ingredient’s emissions have been deemed negligible in the context of the whole product(s) it is used in, so it has been excluded from the total product’s emissions calculation.',
  'No Suitable Match - branded or whole product':
    'This ingredient is a branded or whole product, so it has been excluded from the product assessment.',
  'No Suitable Match - drink':
    'This ingredient is categorised as a drink, so it has been excluded from the product assessment.',
  'Targets - Current Value':
    'Your current value represents where you\'re at now with the metric you\'ve chosen. For example, if your % of A rated products is 30% and you want to target 50% as your goal, your "current" value will be 30%',
};

export const LIFE_CYCLE_COLORS: { [key: string]: string } = {
  Transport: '#fdb588',
  Packaging: '#ffd78e',
  Processing: '#8cbae0',
  Farming: '#c2ddcc',
  Ingredients: '#c2ddcc',
};

export const EMISSIONS_CATEGORIES_COLORS: { [key: string]: string } = {
  A: 'hsl(144, 67%, 31%)',
  B: 'hsl(102, 40%, 50%)',
  C: 'hsl(46, 100%, 55%)',
  D: 'hsl(24, 100%, 52%)',
  E: 'hsl(352, 89%, 38%)',
};

export const RECIPE_LIFE_CYCLE_INFO = {
  Ingredient: {
    text: 'The ingredients table highlights which food contributes the most to your carbon footprint. Every food has an emissions factor for agriculture and processing, and uses general averages to model the packaging and transport of the food to your first location.',
    image: 'farm',
  },
  Processing: {
    text: 'This page includes details of all manufacturing and packing steps, as well as storage up and down the supply chain. Processing for individual ingredients is separate, and reported within the ingredients table. If you’re a restaurant or caterer, then this section may not be relevant for you.',
    image: 'processing',
  },
  Packaging: {
    text: 'Your packaging involves any single-use materials used to contain, ship, and sell your products or meals, from the point at which the individual ingredients reach your manufacturer, up to the point they reach the store, or home. We take into account whether any materials are recycled. See below a breakdown of your product or meal’s single-use packaging, to identify highest-emitting materials. ',
    image: 'packaging',
  },
  Transport: {
    text: 'Your transport covers the part of the supply chain between your manufacturing site or restaurant, up to when the products or meals arrive at the store, or homes. To get a full picture, we map out an average journey for each step of the way - understanding the total distances, mode of transport used, what percentage of products or meals travels this route. The below table shows you what your highest-emitting journeys are.',
    image: 'transport',
  },
};

export const NUMBER_TO_MONTH_MAPPING: { [key: string]: string } = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export const NUMBER_TO_FULL_MONTH_MAPPING: { [key: string]: string } = {
  // NB: This const is temporary and will need refactoring due to repetition (see above const)
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

export const ORGANIZATIONS = {
  KFC_CANADA: 'KFC Canada',
  AB_WORLD_FOODS: 'AB World Foods',
  MyEmissionsLocal: 'MyEmissionsLocal',
  APP_DEMO: 'My Emissions - demo account',
  UMASS: 'University of Massachusetts',
  FSC_GROUP: 'The FSC Group',
  SELFRIDGES: 'Selfridges',
  N8LTD: 'N8 LTD',
  MyEmissionsSandbox: 'My Emissions - Sandbox',
};
