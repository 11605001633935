import { Chip, Typography } from '@mui/material';

interface Props {
  value: number;
  methodologyVersion: number;
  showValue?: boolean;
}
export default function RatingsThresholdSlider({
  value,
  methodologyVersion,
  showValue = true,
}: Props) {
  const METHODOLOGY_VERSION_UPPER_LIMIT = methodologyVersion < 2 ? 6.125 : 6.75;
  const equivalentPerc = (value: number) => {
    if (value >= METHODOLOGY_VERSION_UPPER_LIMIT) {
      return 99;
    }
    return (value * 100) / (METHODOLOGY_VERSION_UPPER_LIMIT + 0.2);
  };
  return (
    <>
      <section>
        <div className="flex h-[7px] flex-row">
          <div
            style={{
              width: methodologyVersion < 2 ? '26.53%' : '25.923%',
              backgroundColor: 'var(--rating-a)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              marginRight: '1px',
            }}
          ></div>
          <div
            style={{
              width: methodologyVersion < 2 ? '18.37%' : '18.52%',
              backgroundColor: 'var(--rating-b)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              marginRight: '1px',
            }}
          ></div>
          <div
            style={{
              width: methodologyVersion < 2 ? '18.37%' : '18.52%',
              backgroundColor: 'var(--rating-c)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              marginRight: '1px',
            }}
          ></div>
          <div
            style={{
              width: methodologyVersion < 2 ? '18.37%' : '18.52%',
              backgroundColor: 'var(--rating-d)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              marginRight: '1px',
            }}
          ></div>
          <div
            style={{
              width: methodologyVersion < 2 ? '18.37%' : '18.52%',
              backgroundColor: 'var(--rating-e)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
            }}
          ></div>
        </div>
        <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
          <div
            style={{
              left:
                equivalentPerc(value) > 98
                  ? `calc(${equivalentPerc(value)}% - 7.5px)`
                  : `${equivalentPerc(value)}%`,
              marginTop: '-11px',
              position: 'relative',
              height: '15px',
              width: '15px',
              backgroundColor: '#ffffff',
              borderRadius: '50%',
              border: '3px solid #027fff',
              display: 'inline-block',
              alignSelf: 'flex-start',
            }}
          />

          {showValue && (
            <>
              <div
                style={{
                  left: `${equivalentPerc(value)}%`,
                  marginLeft: '-8px',
                  position: 'relative',
                  height: '10px',
                  width: '2px',
                  marginTop: '5px',
                  backgroundColor: '#ffffff',
                  border: '2px solid #efeff7',
                  display: 'inline-block',
                  alignSelf: 'flex-start',
                }}
              ></div>

              <div
                style={{
                  left:
                    value > 3.5
                      ? `calc(${equivalentPerc(value)}% - 114px)`
                      : `${equivalentPerc(value)}%`,
                  position: 'absolute',
                  alignSelf: 'flex-start',
                  marginTop: '7px',
                }}
              >
                <Chip
                  label={
                    <Typography variant="caption" sx={{ fontSize: '11px' }}>
                      {value} kgCO<sub>2</sub>e/kg<sub>food</sub>
                    </Typography>
                  }
                  size="small"
                  sx={{
                    backgroundColor: '#efeff7',
                    width: '126px',
                  }}
                />
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
