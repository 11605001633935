import { AvailableIngredients, Unit } from '@shared/api/types';
import SelectFormField, {
  SelectOption,
} from '@shared/components/form/select-form-field';
import { useGetUnitUuid } from '@shared/components/product-form/hooks/use-get-unit-uuid';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface UnitPickerProps {
  name: string;
  units: Unit[];
  className?: ClassValue;
  availableIngredients?: AvailableIngredients;
  watchIngredientUuid?: string;
  index?: number;
}

type UnitOption = {
  allUnits: SelectOption[];
  availableUnits: SelectOption[];
  groupedAllUnits: { [key: string]: SelectOption[] };
  groupedAvailableUnits: { [key: string]: SelectOption[] };
};

function getUnitOption(unit: Unit) {
  return {
    value: unit.uuid,
    label: unit.longDesc + 's',
    shortLabel: unit.shortDesc,
  };
}

function useSetUnits(
  availableIngredients: AvailableIngredients,
  units: Unit[],
  watchIngredientUuid: string
) {
  const baseFoods: AvailableIngredients['baseFoods'] =
    availableIngredients?.baseFoods || [];
  const products: AvailableIngredients['products'] =
    availableIngredients?.products || [];

  const availableIngredientsList = [...baseFoods, ...products];

  const formattedUnits = units.reduce(
    (acc, unit: Unit) => {
      const newAcc = { ...acc };

      const selectedIngredient = availableIngredientsList.find(
        (ingredient) => ingredient.uuid === watchIngredientUuid
      );

      if (selectedIngredient?.availableUnitTypes.includes(unit.type)) {
        newAcc.groupedAvailableUnits[unit.type] = [
          ...(newAcc.groupedAvailableUnits[unit.type] || []),
          getUnitOption(unit),
        ];
        newAcc.availableUnits.push(getUnitOption(unit));
      }

      newAcc.groupedAllUnits[unit.type] = [
        ...(newAcc.groupedAllUnits[unit.type] || []),
        getUnitOption(unit),
      ];

      newAcc.allUnits.push(getUnitOption(unit));

      return newAcc;
    },
    {
      allUnits: [],
      availableUnits: [],
      groupedAvailableUnits: {},
      groupedAllUnits: {},
    } as UnitOption
  );

  return formattedUnits;
}

export function UnitPicker({
  name,
  units,
  className,
  availableIngredients,
  watchIngredientUuid,
  index,
}: UnitPickerProps) {
  const defaultUnit = useGetUnitUuid('g');
  const { setValue, getValues } = useFormContext();
  const watchUnitUuid = getValues(`ingredients.${index}.unitUuid`);

  const { availableUnits, allUnits, groupedAllUnits, groupedAvailableUnits } =
    useSetUnits(availableIngredients!, units, watchIngredientUuid!);

  useEffect(() => {
    if (!watchUnitUuid) {
      setValue(`ingredients.${index}.unitUuid`, defaultUnit);
    }
  });

  const resetUnitIfUnavailable = useCallback(() => {
    if (
      watchUnitUuid &&
      !availableUnits?.find((unit) => unit.value === watchUnitUuid)
    ) {
      setValue(`ingredients.${index}.unitUuid`, '');
    }
  }, [availableUnits, index, setValue, watchUnitUuid]);

  useEffect(() => {
    if (watchIngredientUuid) {
      resetUnitIfUnavailable();
    }
  }, [watchIngredientUuid, resetUnitIfUnavailable]);

  return (
    <SelectFormField
      name={name}
      options={(availableUnits.length > 0 && availableUnits) || allUnits}
      groupedOptions={
        (availableUnits.length > 0 && groupedAvailableUnits) || groupedAllUnits
      }
      label={index === 0 ? 'Unit' : ''}
      disabled={!watchIngredientUuid}
      useShortLabel
      className={cn('', className)}
    />
  );
}
