import { EmissionsCategoryEnum } from '@shared/api/types';
import Text from '@shared/components/content/text';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { FC } from 'react';

interface RatingExplainerProps {
  rating: EmissionsCategoryEnum;
  carbonIntensity: number;
  className?: ClassValue;
}

const RatingExplainer: FC<RatingExplainerProps> = ({
  rating,
  carbonIntensity,
  className,
}) => (
  <Text className={cn('text-center', className)}>
    Your product has a carbon intensity of {carbonIntensity.toFixed(2)}, meaning
    that for every 1 kg of it that is produced, {carbonIntensity.toFixed(2)} kg
    of carbon dioxide equivalent is emitted. This makes it an {rating}-rated
    product on our carbon rating scale.
  </Text>
);

export default RatingExplainer;
