import IngredientPicker from '@admin/components/ingredient-picker';
import { useGetAvailableIngredients, useGetUnitList } from '@shared/api';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { UnitPicker } from '@shared/components/product-form/components/unit-picker';
import { Button } from '@shared/components/ui/button';
import { FormLabel } from '@shared/components/ui/form';
import { Skeleton } from '@shared/components/ui/skeleton';
import { cn } from '@shared/lib/utils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface RecipeIngredientRowProps extends RepeatableRowComponentProps {}

export default function RecipeIngredientRow({
  index,
  remove,
  setDisableAdd,
  includeErrorMessage,
}: RecipeIngredientRowProps) {
  const {
    data: availableIngredients,
    isLoading: availableIngredientsLoading,
    isError: isAvailableIngredientsError,
    error: availableIngredientsError,
  } = useGetAvailableIngredients({
    include: ['products'],
  });

  const {
    data: units,
    isLoading: unitsIsLoading,
    isError: isUnitsError,
    error: unitsError,
  } = useGetUnitList();

  const { getValues, watch } = useFormContext();

  const watchIngredientUuid = watch(`ingredients.${index}.entityUuid`);

  useEffect(() => {
    availableIngredientsLoading || unitsIsLoading
      ? setDisableAdd(true)
      : setDisableAdd(false);
  }, [setDisableAdd, availableIngredientsLoading, unitsIsLoading]);

  if (availableIngredientsLoading || unitsIsLoading) {
    return (
      <div className="grid grid-cols-12 items-end gap-2">
        <Skeleton className="col-span-7 h-10 rounded-lg" />
        <Skeleton className="col-span-2 h-10 rounded-lg" />
        <Skeleton className="col-span-2 h-10 rounded-lg" />
        <Skeleton className="col-span-1 h-10 rounded-lg" />
      </div>
    );
  }

  if (!units) {
    throw new Error(
      `Failed to load units for ingredient rows ${unitsError || 'No data'}`
    );
  }

  if (!availableIngredients) {
    throw new Error(
      `Failed to load available ingredients for ingredient rows: ${availableIngredientsError || 'No data'}`
    );
  }

  return (
    <div className="grid grid-cols-12 items-start gap-2">
      <IngredientPicker
        name={`ingredients.${index}.entityUuid`}
        index={index}
        showLabel={index === 0}
        availableIngredients={availableIngredients}
        className="col-span-7"
        includeErrorMessage={includeErrorMessage}
      />
      <InputFormField
        name={`ingredients.${index}.quantity`}
        label={index === 0 ? 'Quantity' : ''}
        disabled={!watchIngredientUuid}
        type="number"
        min={0.001}
        step="any"
        className="col-span-2"
        inputClassName="text-right"
      />
      <UnitPicker
        name={`ingredients.${index}.unitUuid`}
        units={units}
        availableIngredients={availableIngredients}
        watchIngredientUuid={watchIngredientUuid}
        index={index}
        className="col-span-2"
      />
      <div className="col-span-1 grid items-end">
        {index === 0 && (
          <FormLabel className="mb-0 opacity-0">Remove</FormLabel>
        )}
        <Button
          type="button"
          variant="outline"
          size="sm"
          className={cn('size-10', {
            'opacity-30': getValues('ingredients').length === 1,
            'mt-2': index === 0,
          })}
          disabled={getValues('ingredients').length === 1}
          onClick={() => remove(index)}
        >
          <Icons.x className="size-4" />
        </Button>
      </div>
    </div>
  );
}
