/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `base_food` - BaseFood
* `product` - Product
 */
export type EntityTypeEnum = typeof EntityTypeEnum[keyof typeof EntityTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityTypeEnum = {
  base_food: 'base_food',
  product: 'product',
} as const;
