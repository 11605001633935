import { useMutation } from '@tanstack/react-query';
import { reggieClient } from './client';

const generateRecipe = async ({
  name,
  user_uuid,
}: {
  name: string;
  user_uuid?: string;
}) => {
  const { data } = await reggieClient.post('/generate-recipe/', {
    name,
    user_uuid,
  });
  return data as GeneratedRecipe;
};

export const useGenerateRecipe = () => {
  return useMutation({
    mutationFn: generateRecipe,
  });
};
