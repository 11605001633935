import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import React, { ReactNode } from 'react';

type HeadingVariant = 'title' | 'subtitle' | 'section';

interface HeadingProps {
  variant?: HeadingVariant;
  className?: ClassValue;
  children: ReactNode;
}

const baseStyles = 'font-bold leading-tight';

// Mapping of variants to their respective tag and style
const variantStyles: Record<
  HeadingVariant,
  { tag: keyof JSX.IntrinsicElements; style: ClassValue }
> = {
  title: { tag: 'h1', style: 'text-3xl' },
  subtitle: { tag: 'h2', style: 'text-2xl' },
  section: { tag: 'h3', style: 'text-lg font-medium' },
};

const Heading: React.FC<HeadingProps> = ({
  variant = 'title',
  className,
  children,
}) => {
  const { tag: Tag, style: variantStyle } = variantStyles[variant];
  const styles = cn(baseStyles, variantStyle, className);

  return <Tag className={styles}>{children}</Tag>;
};

export default Heading;
