import { SortOption } from '@app/components/tables/product-table/product-table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shared/components/ui/select';
import { ColumnSort } from '@tanstack/react-table';

interface DataTableSortBySelectProps {
  sortBy: ColumnSort;
  setSortBy: (sortBy: ColumnSort) => void;
  sortOptions: SortOption[];
}

export default function DataTableSortBySelect({
  sortBy,
  setSortBy,
  sortOptions,
}: DataTableSortBySelectProps) {
  return (
    <Select
      value={sortBy.id as string}
      onValueChange={(value) => setSortBy({ id: value, desc: sortBy.desc })}
    >
      <SelectTrigger className="h-8 w-auto space-x-1.5 border-dashed text-xs font-medium">
        <SelectValue
          placeholder={
            sortOptions.find((option) => option.id === sortBy.id)?.label ||
            'Sort by'
          }
        />
      </SelectTrigger>
      <SelectContent>
        {sortOptions.map((option) => (
          <SelectItem key={option.id} value={option.id}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
