import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
});

const sentry = {
  log: (error: unknown) => {
    if (import.meta.env.MODE === 'development') {
      console.error(error);
    }
    Sentry.captureException(error);
  },
  setUser: (email: string) => Sentry.setUser({ email }),
  resetUser: () => Sentry.setUser(null),
};

export default sentry;
