interface DateCellProps {
  date: string;
}

export function DateCell({ date }: DateCellProps) {
  const dateObj = new Date(date);

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const year = dateObj.getFullYear();

  const formattedDate = `${day} ${month}, ${year}`;
  return <p>{formattedDate}</p>;
}
