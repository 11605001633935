import {
  EMISSIONS_CATEGORIES_COLORS,
  LIFE_CYCLE_COLORS,
  NUMBER_TO_FULL_MONTH_MAPPING,
  NUMBER_TO_MONTH_MAPPING,
} from './constants';

export function getColorByLifeCycleStage(lifeCycleStage: string): string {
  return LIFE_CYCLE_COLORS[lifeCycleStage];
}

export function getColorByEmissionsCategory(emissionsCategory: string): string {
  return EMISSIONS_CATEGORIES_COLORS[emissionsCategory];
}

export function handleLeadingZeros(
  number: number,
  numDecimalPlaces: number
): any {
  let result = number.toString();
  if (numDecimalPlaces === 1) {
    if (!number.toString().includes('.')) {
      result = number + '.0';
    }
    return result;
  } else if (numDecimalPlaces > 1) {
    if (!number.toString().includes('.')) {
      result = number + '.';
    }
    while (result.length - result.indexOf('.') < numDecimalPlaces + 1) {
      result = result + '0';
    }
  }

  return result;
}

export function collectionFormDataIsValid(
  collectionName: string,
  collectionDescription: string,
  collectionProductsUuids: string[]
) {
  if (collectionName.length === 0 || collectionProductsUuids.length === 0) {
    return false;
  }
  return true;
}

export function getFlagEmoji(countryCode: string) {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));
}

export function roundNumberToNDecimalPlaces(
  number: number,
  numDecimalPlaces: number = 1,
  addTrailingZero: boolean = true
): any {
  const factorOfTen = Math.pow(10, numDecimalPlaces);
  let result = Math.round(number * factorOfTen) / factorOfTen;
  if (addTrailingZero) {
    result = handleLeadingZeros(result, numDecimalPlaces);
  }
  return result;
}

export function getLegendDateFromYYYYMMDate(dateAsYYYYMM: string): string {
  const year = dateAsYYYYMM.slice(0, 4);
  const month = NUMBER_TO_MONTH_MAPPING[dateAsYYYYMM.slice(5, 7)];
  return month + ' ' + year;
}

export function sum(a: number, b: number): number {
  return a + b;
}

export function setAccountData(accountData: {
  userName: string;
  userEmail: string;
  organizationName: string;
}) {
  const { userName, userEmail, organizationName } = accountData;
  localStorage.setItem('userName', userName);
  localStorage.setItem('organizationName', organizationName);
  localStorage.setItem('userEmail', userEmail);
}

export function computeMaxValue(arrayOfObjects: any, filterByKey: string) {
  let max = arrayOfObjects[0][filterByKey];
  for (let i = 0; i < arrayOfObjects.length; i++) {
    if (arrayOfObjects[i][filterByKey] > max) {
      max = arrayOfObjects[i][filterByKey];
    }
  }
  return max;
}

export const getUserFriendlyDate = (dateAsYYYYMMDD: string) => {
  // refactor: no need to have both this function and the formatDate one in statsCard.
  const year = dateAsYYYYMMDD.slice(0, 4);
  const month = dateAsYYYYMMDD.slice(5, 7);
  const day = dateAsYYYYMMDD.slice(8, 10);
  return day + ' ' + NUMBER_TO_FULL_MONTH_MAPPING[month] + ' ' + year;
};

export function getUserFriendlyDate2(dateAsStr: string): string {
  // dateAsStr comes in as YYYY-MM-DDTHH:mm:ss.sssZ (e.g. 2022-09-20T16:06:48.558053Z)
  const year = dateAsStr.slice(0, 4);
  const month = NUMBER_TO_MONTH_MAPPING[dateAsStr.slice(5, 7)];
  const day = dateAsStr.slice(8, 10);
  return day + ' ' + month + ' ' + year;
}

export const computeProductsExportFileName = (): string => {
  const today = new Date();
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(); // "2022-10-03"
  return `products_${date}`; // "products_2022-10-03"
};
