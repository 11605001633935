import { useGetUnitList } from '@shared/api';
import { useMemo } from 'react';

export function useGetUnitUuid(unit: string) {
  const { data: units } = useGetUnitList();

  const unitUuid = useMemo(() => {
    if (!units) {
      return null;
    }
    return units.find((u) => u.shortDesc === unit || u.longDesc === unit)?.uuid;
  }, [unit, units]);

  return unitUuid;
}
