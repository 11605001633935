/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import {
  useInfiniteQuery,
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Batch,
  BatchesListParams
} from '../../types'
import { apiClient } from '../../client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const batchesList = (
    params?: BatchesListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<Batch[]>(
      {url: `/batches/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getBatchesListQueryKey = (params?: BatchesListParams,) => {
    return [`/batches/`, ...(params ? [params]: [])] as const;
    }

    
export const getBatchesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof batchesList>>, TError = unknown>(params?: BatchesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBatchesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof batchesList>>> = ({ signal, pageParam }) => batchesList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchesList>>, TError, TData> & { queryKey: QueryKey }
}

export type BatchesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof batchesList>>>
export type BatchesListInfiniteQueryError = unknown

export const useBatchesListInfinite = <TData = Awaited<ReturnType<typeof batchesList>>, TError = unknown>(
 params?: BatchesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getBatchesListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getBatchesListQueryOptions = <TData = Awaited<ReturnType<typeof batchesList>>, TError = unknown>(params?: BatchesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof batchesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBatchesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof batchesList>>> = ({ signal }) => batchesList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof batchesList>>, TError, TData> & { queryKey: QueryKey }
}

export type BatchesListQueryResult = NonNullable<Awaited<ReturnType<typeof batchesList>>>
export type BatchesListQueryError = unknown

export const useBatchesList = <TData = Awaited<ReturnType<typeof batchesList>>, TError = unknown>(
 params?: BatchesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof batchesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getBatchesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



