import Axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

export const AXIOS_INSTANCE = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiClient = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat', indices: false }),
  }).then(({ data }) => data);

  // @ts-expect-error "cancel is not part of Promise"
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};
