import { EmissionsCategoryEnum, StatusEnum } from '@shared/api/types';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { motion } from 'framer-motion';
import Text from '../content/text';
import Logo from '../logo/logo';
import RatingsThresholdSlider from '../ratings-thresholds-slider';
import { Card } from '../ui/card';

export interface RatingCardProps {
  rating: EmissionsCategoryEnum;
  carbonIntensity: number;
  status?: StatusEnum;
  className?: ClassValue;
}

const RatingCard = ({
  rating,
  carbonIntensity,
  className,
}: RatingCardProps) => {
  const ratingImage = new URL(
    `./clouds/cloud-${rating.toLowerCase()}.svg`,
    import.meta.url
  );

  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ rotate: 360, scale: 1 }}
      transition={{
        type: 'spring',
        delay: 0.3,
        stiffness: 238,
        damping: 58,
      }}
    >
      <Card
        className={cn(
          'flex w-[240px] flex-col items-center p-6 shadow-lg shadow-gray-100',
          className
        )}
      >
        <Text variant="cardTitle">Carbon rating</Text>
        <img
          src={ratingImage.href}
          className="my-4 h-[64px] w-[108px]"
          alt={`Emissions rating: ${rating}`}
        />
        <div className="mb-6 w-full px-2">
          <RatingsThresholdSlider
            value={carbonIntensity}
            methodologyVersion={2}
            showValue={false}
          />
        </div>
        <Text variant="heroValue">{carbonIntensity.toFixed(2)}</Text>
        <Text variant="unit">
          kgCO<sub>2</sub>e / kg<sub>food</sub>
        </Text>
        <Logo variant="name" className="mt-8 w-[148px]" />
      </Card>
    </motion.div>
  );
};

export default RatingCard;
