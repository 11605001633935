import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface TextLinkProps {
  to: string;
  internal?: boolean;
  routerLinkProps?: LinkProps;
  className?: ClassValue;
  children: ReactNode;
  color?: 'light' | 'dark';
}

const TextLink: FC<TextLinkProps> = ({
  to,
  internal = true,
  className,
  routerLinkProps,
  children,
  color,
}) => {
  const styles = cn(
    'text-primary-500 hover:text-primary-600',
    {
      'text-primary-foreground hover:text-primary-300': color === 'light',
    },
    className
  );

  return internal ? (
    <Link to={to} className={styles} {...routerLinkProps}>
      {children}
    </Link>
  ) : (
    <a href={to} className={styles}>
      {children}
    </a>
  );
};

export default TextLink;
