import { AxiosRequestConfig } from 'axios';
import { useEffect, useMemo, useState } from 'react';

export const baseAxiosConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
};

const useCookie = (name: string) => {
  const [cookie, setCookie] = useState<string>();

  useEffect(() => {
    const cookieValue =
      document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '') || '';

    setCookie(cookieValue);
  }, [name]);

  return cookie;
};

export const useAdminAxiosConfig = () => {
  const csrfToken = useCookie('csrftoken');
  const config = useMemo(() => {
    return {
      ...baseAxiosConfig,
      headers: {
        'X-CSRFToken': csrfToken,
      },
    };
  }, [csrfToken]);

  return config;
};
