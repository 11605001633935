import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { DataTableFilterField } from '../types';

interface UseDataTableProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  filterFields?: DataTableFilterField<TData>[];
  defaultSorting?: ColumnSort[];
  columnVisibilityState?: VisibilityState;
  pageSize?: number;
}

export function useDataTable<TData, TValue>({
  data,
  columns,
  filterFields = [],
  defaultSorting,
  columnVisibilityState,
  pageSize,
}: UseDataTableProps<TData, TValue>) {
  function setDefaultFilters(
    filters: DataTableFilterField<TData>[]
  ): ColumnFiltersState {
    const defaultFilters: ColumnFiltersState = [];

    filters.map((filter: DataTableFilterField<TData>) => {
      if (filter.defaultValues) {
        defaultFilters.push({
          id: filter.label,
          value: filter.value,
        });
      }
    });

    return defaultFilters;
  }

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: pageSize || 10,
  });

  const [sorting, setSorting] = React.useState<SortingState>(
    defaultSorting || []
  );
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    (filterFields && setDefaultFilters(filterFields)) || []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>(columnVisibilityState ?? {});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
  });

  return { table };
}
