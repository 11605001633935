import { Icons } from '@shared/components/content/icons';
import { Button } from '@shared/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@shared/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@shared/components/ui/popover';
import { cn } from '@shared/lib/utils';
import { CommandList } from 'cmdk';
import Fuse, { FuseOptionKey } from 'fuse.js';
import * as React from 'react';

export interface Category<T> {
  name: string;
  items: T[];
  searchableKeys: FuseOptionKey<T>[];
  valueKey: keyof T;
  labelKey: keyof T;
  requireSearch?: boolean;
  onSelect: (selectedItem: T) => void;
}

export interface CategorizedPickerProps<T> {
  categories: Category<T>[];
  value?: T[keyof T];
  error?: string;
  onCreate?: () => void;
}

interface UseSearchProps<T> {
  items: T[];
  searchQuery: string;
  searchKeys: FuseOptionKey<T>[];
}

const useSearch = <T,>({
  items,
  searchQuery,
  searchKeys,
}: UseSearchProps<T>): T[] => {
  const fuse = React.useMemo(() => {
    return new Fuse(items, {
      keys: searchKeys,
      threshold: 0.1,
      shouldSort: false,
    });
  }, [items, searchKeys]);

  if (!searchQuery) {
    return items;
  }
  return fuse.search(searchQuery).map((result) => result.item);
};

export function CategorizedPicker<T>({
  categories,
  value,
  error,
  onCreate,
}: CategorizedPickerProps<T>) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(value || '');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [activeCategory, setActiveCategory] = React.useState(categories[0]);

  const filteredItems = useSearch({
    items: activeCategory.items,
    searchQuery: searchQuery,
    searchKeys: activeCategory.searchableKeys,
  });

  const getSelectedLabel = (): string => {
    if (selectedValue) {
      const selectedItem = categories
        .flatMap((category) => category.items)
        .find((item) => item[activeCategory.valueKey] === selectedValue);
      return selectedItem
        ? (selectedItem[activeCategory.labelKey] as string)
        : '';
    }
    return `Select ${activeCategory.name.toLowerCase()}...`;
  };

  const showStartTypingMessage = activeCategory.requireSearch && !searchQuery;

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        setSearchQuery('');
      }}
    >
      <PopoverTrigger asChild className="w-full">
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'h-10 justify-between font-normal shadow-none hover:bg-background truncate flex flex-row items-center',
            {
              'text-gray-400 hover:text-gray-400': !selectedValue,
              'border-error-500': error,
            }
          )}
        >
          <span className="overflow-hidden text-ellipsis">
            {getSelectedLabel()}
          </span>
          <Icons.chevronDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="max-h-[--radix-popover-content-available-height] w-full max-w-[--radix-popover-content-available-width] p-0"
      >
        <Command shouldFilter={false}>
          <CommandInput
            placeholder={`Search ${activeCategory.name.toLowerCase()}...`}
            onValueChange={(value) => {
              setSearchQuery(value);
            }}
          />
          <div className="flex flex-row">
            <div className="flex w-[164px] flex-col border-r p-3">
              {categories.map((category) => (
                <div
                  key={category.name}
                  onClick={() => {
                    setActiveCategory(category);
                  }}
                  className={`flex items-center justify-between rounded-sm px-2 py-1.5 text-sm hover:cursor-default ${
                    activeCategory.name === category.name ? 'bg-accent' : ''
                  }`}
                >
                  <span className="mr-1 text-sm">{category.name}</span>
                  <Icons.chevronRight className="size-4" />
                </div>
              ))}
              {onCreate && (
                <div className="flex h-full items-end">
                  <Button
                    variant="outline"
                    className="flex w-full items-center"
                    icon={<Icons.plusCircle />}
                    onClick={onCreate}
                  >
                    Create new
                  </Button>
                </div>
              )}
            </div>

            <div className="size-[248px] overflow-y-scroll p-2">
              <CommandEmpty>
                {showStartTypingMessage
                  ? 'Start typing to search'
                  : `No ${activeCategory.name.toLowerCase()} found.`}
              </CommandEmpty>
              <CommandGroup>
                <CommandList>
                  {!showStartTypingMessage &&
                    filteredItems.length > 0 &&
                    filteredItems.map((item) => (
                      <CommandItem
                        key={item[activeCategory.valueKey] as React.Key}
                        value={item[activeCategory.valueKey] as string}
                        onSelect={(currentValue) => {
                          const selectedItem = filteredItems.find(
                            (itm) =>
                              itm[activeCategory.valueKey] === currentValue
                          );
                          setSelectedValue(
                            currentValue === selectedValue ? '' : currentValue
                          );
                          setOpen(false);
                          if (selectedItem) {
                            activeCategory.onSelect(selectedItem);
                          }
                        }}
                      >
                        <div className="flex flex-row items-center justify-between">
                          <div>
                            <Icons.check
                              className={cn(
                                'mr-2 h-4 w-4',
                                item[activeCategory.valueKey] === selectedValue
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                          </div>
                          {item[activeCategory.labelKey] as string}
                        </div>
                      </CommandItem>
                    ))}
                </CommandList>
              </CommandGroup>
            </div>
          </div>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
