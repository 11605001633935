import { useCallback, useEffect, useState } from 'react';
import { Progress } from './ui/progress';

interface StatusMessage {
  message: string;
  icon: React.ReactNode;
}

interface ProgressBarWithDurationProps {
  duration: number;
  statusMessages: StatusMessage[];
  forceComplete?: boolean;
}

const averageIncrement = 10;
const numUpdates = 100 / averageIncrement;

export default function ProgressBarWithDuration({
  duration,
  statusMessages,
  forceComplete,
}: ProgressBarWithDurationProps) {
  const [value, setValue] = useState(0);
  const intervalDelay = duration / numUpdates;

  const runLoader = useCallback(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => {
        const randomIncrement = Math.floor(Math.random() * 10 + 5);
        const newValue = Math.min(prevValue + randomIncrement, 100);

        if (newValue >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newValue;
      });
    }, intervalDelay);

    return () => clearInterval(interval);
  }, [intervalDelay]);

  useEffect(() => {
    runLoader();
  }, [runLoader]);

  useEffect(() => {
    if (forceComplete) {
      setValue(100);
    }
  }, [forceComplete]);

  let statusMessageIndex = 0;
  if (value < 100) {
    statusMessageIndex = Math.floor((value / 100) * statusMessages.length);
  } else {
    statusMessageIndex = statusMessages.length - 1;
  }

  return (
    <div className="flex min-w-full flex-col">
      <div className="flex w-full items-center justify-center font-mono">
        {statusMessages[statusMessageIndex].icon}
        <span className="ml-2">
          {statusMessages[statusMessageIndex].message}
        </span>
      </div>
      <Progress
        value={value}
        className="mt-4 h-4 min-w-full"
        indicatorBgColor={value >= 100 ? 'bg-secondary' : 'bg-warning'}
      />
    </div>
  );
}
