import { capitalizeFirstLetter, formatUnderscoreText } from '@shared/lib/utils';
import { useMemo } from 'react';

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

export function useGetStaticFilterOptions<E extends Enum<E>>(enumType: E) {
  const options = useMemo(() => {
    const optionList = Object.values(enumType).map((value) => {
      return {
        label: capitalizeFirstLetter(formatUnderscoreText(value)),
        value: value,
      };
    });

    return optionList;
  }, [enumType]);

  return options;
}
